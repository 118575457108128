img.portrait {
  --aspect-x: 21;
  --aspect-y: 9;
  --portrait-width: calc(var(--body-width) * 0.66);
  --portrait-max-width: calc(100vw - calc(2 * var(--page-margin)));

  width: var(--portrait-width);
  height: calc(var(--portrait-width) / var(--aspect-x) * var(--aspect-y));
  max-width: var(--portrait-max-width);
  max-height: calc(
    var(--portrait-max-width) / var(--aspect-x) * var(--aspect-y)
  );
  margin-bottom: 10em;
  object-fit: cover;
}
