svg.background {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  display: block;
  z-index: -1;
  overflow: hidden;
}

circle.palette-1 {
  fill: rgb(255, 200, 255);
}

circle.palette-2 {
  fill: rgb(128, 255, 255);
}

circle.palette-3 {
  fill: rgb(255, 255, 180);
}
