nav {
  display: flex;
  padding: 1em 0em 1.5em 0em;
  justify-content: space-between;
}

.nav-column {
  font-size: 0.75em;
}

@media (min-width: 300px) {
  .nav-column {
    font-size: 1em;
    padding: 2em 0em 2.5em 0em;
  }
}

@media (min-width: 550px) {
  .nav-column {
    flex: 1;
  }
}

@media (min-width: 900px) {
  nav {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.col-right {
  display: flex;
  justify-content: end;
  flex-direction: column;
  position: relative;
}

.col-center {
  display: flex;
  justify-content: center;
}

/* navbar items spacing */
div.col-center > a {
  margin-right: 0.5em;
}

a.nav-item.current {
  text-decoration-color: var(--text-col-dark);
}

button.menu-button {
  display: grid;
  cursor: pointer;
}

.menu-button {
  text-align: right;
}

.menu {
  position: absolute;
  width: max-content;
  right: 0;
}

.relative {
  position: relative;
}

/* Underline animation */
a.nav-item,
button {
  text-decoration: underline 0.05em rgba(0, 0, 0, 0);
  transition: text-decoration-color 300ms, color 300ms;
}

a.nav-item:hover,
button:hover {
  text-decoration: underline 0.05em rgba(0, 0, 0, 1);
}

/* menu animation */
.collapse {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.1s, opacity 0.1s linear;
}

.expand {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.1s linear;
}
